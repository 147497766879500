import React, { useContext } from 'react';
import styled from 'styled-components';

import { LangContext } from '../../context/LangContext';

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const RadioText = styled.div`
  font-size: 1.8rem;
`;

const RadioInput = styled.input`
  display: none;
`;

const RadioLabel = styled.label`
  padding: 0.6rem 1rem;

  &:nth-child(2) {
    border-left: 1px solid var(--color-text);
  }
`;

const RadioButton = ({ value, isChecked }) => {
  const textStyle = isChecked
    ? { color: 'var(--color-black)' }
    : { color: 'var(--color-grey-light)' };

  return (
    <RadioLabel>
      <RadioInput type="radio" name="lang_radio" value={value} />
      <RadioText style={textStyle}>{value}</RadioText>
    </RadioLabel>
  );
};

const LanguageSelector = () => {
  const { lang, toggleLang } = useContext(LangContext);

  const handleValueChange = e => {
    toggleLang(e.target.value);
  };

  return (
    <FlexContainer onChange={e => handleValueChange(e)}>
      <RadioButton value="el" isChecked={lang === 'el'} />
      <RadioButton value="en" isChecked={lang === 'en'} />
    </FlexContainer>
  );
};

export default LanguageSelector;
