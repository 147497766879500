const theme = {
  colors: {
    primary: '#ffd445',
    greyDark: '#222',
    greyLight: '#555',
    greyLighter: '#d4d1cb',
    errorRed: '#ff0333',
    successGreen: '#5cb85c',
    black: '#000',
    white: '#fff',
    text: '#333',
  },
  mediaQueries: {
    smallest: `only screen and (max-width: 25em)`,
    smaller: 'only screen and (max-width: 31.25em)',
    small: 'only screen and (max-width: 37.5em)',
    medium: 'only screen and (max-width: 56em)',
    large: 'only screen and (max-width: 80em)',
    larger: 'only screen and (max-width: 90em)',
    largest: 'only screen and (max-width: 100em)',
  },
};

export default theme;
