import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTrail, animated } from 'react-spring';

import { LangContext } from '../../../../context/LangContext';

import NavItem from './NavItem/NavItem';
import LangSelector from '../../../LangSelector/LangSelector';

const LINKS = [
  { name: { el: 'Αρχική', en: 'Home' }, link: '/' },
  { name: { el: 'Προιοντα', en: 'Products' }, link: '/products' },
  { name: { el: 'Επικοινωνία', en: 'Contact' }, link: '/contact' },
];

const StyledNav = styled.nav`
  display: flex;
  overflow: hidden;
  flex: 1;
  flex-direction: ${({ mobile }) => (mobile ? 'column' : 'row')};
  justify-content: ${({ mobile }) => (mobile ? 'center' : 'flex-end')};
  align-items: center;

  @media ${({ theme }) => theme.mediaQueries.large} {
    margin-right: ${({ mobile }) => (mobile ? '0rem' : '4rem')};
  }
`;

const LangSelectorWrapper = styled.div`
  margin-left: ${({ mobile }) => (mobile ? '0rem' : '7rem')};
  margin-top: ${({ mobile }) => (mobile ? '6rem' : '0rem')};
`;

const NavItems = ({ mobile, clicked }) => {
  const { lang } = useContext(LangContext);

  // Animation
  const navItemsTrail = useTrail(LINKS.length, {
    delay: 200,
    opacity: 1,
    from: {
      opacity: 0,
      display: 'flex',
      cursor: 'pointer',
    },
  });

  return (
    <StyledNav mobile={mobile}>
      {navItemsTrail.map((propStyles, index) => (
        <animated.div key={LINKS[index].link} style={propStyles}>
          <NavItem
            key={LINKS[index].link}
            name={LINKS[index].name[lang]}
            link={LINKS[index].link}
            clicked={clicked}
          />
        </animated.div>
      ))}
      <LangSelectorWrapper mobile={mobile}>
        <LangSelector />
      </LangSelectorWrapper>
    </StyledNav>
  );
};

export default NavItems;
