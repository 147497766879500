import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
  margin-bottom: 2.3rem;
  color: var(--color-grey);
  text-transform: uppercase;
  text-align: center;
  font-size: 2rem;
  font-weight: 400;
`;

const FooterHeading = ({ children }) => <Div>{children}</Div>;

export default FooterHeading;
