import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
 @import url('https://fonts.googleapis.com/css?family=News+Cycle&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

:focus {
  outline: none;
}

:root {
  --color-primary: ${props => props.theme.colors.primary};
  --color-grey-light: ${props => props.theme.colors.greyLight};
  --color-grey-lighter: ${props => props.theme.colors.greyLighter};
  --color-grey-dark: ${props => props.theme.colors.greyDark};
  --color-error-red: ${props => props.theme.colors.errorRed};
  --color-success-green: ${props => props.theme.colors.successGreen};
  --color-black: ${props => props.theme.colors.black};  
  --color-white: ${props => props.theme.colors.white};  
  --color-text: ${props => props.theme.colors.text};  
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;

  @media ${props => props.theme.mediaQueries.largest} {
    font-size: 60%;
  }

  @media ${props => props.theme.mediaQueries.large} {
    font-size: 57.5%;
  }

  @media ${props => props.theme.mediaQueries.small} {
    font-size: 55%;
  }
}

body {
  font-family: 'News Cycle', sans-serif;
  box-sizing: inherit;
  font-weight: 400;
  line-height: 1.6rem;
  z-index: 0;
}

a, 
input, 
button {
  cursor: pointer;
  outline: none;
  text-decoration: none;
  font-family: inherit;
}
`;
