import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledLink = styled(Link)`
  text-decoration: none;
  font-family: inherit;
  font-weight: 400;
  color: var(--color-black);
  cursor: pointer;
  padding: 1rem 1.7rem;
  font-size: 1.8rem;
  transition: all 0.2s ease-out;

  &:hover,
  &:active,
  &.active {
    text-decoration: underline;
  }

  @media ${({ theme }) => theme.mediaQueries.small} {
    font-size: 1.8rem;
    padding: 0.8rem;
  }
`;

const NavLink = ({ link, info }) => {
  return <StyledLink to={link}>{info}</StyledLink>;
};

export default NavLink;
