import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0.7rem 0;
  font-size: 1.6rem;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  @media ${({ theme }) => theme.mediaQueries.smaller} {
    margin: auto;
  }
`;

const TextWrapper = styled.div`
  color: var(--color-black);
  margin-left: 1rem;
  letter-spacing: 1px;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: var(--color-black);
  background-color: transparent;
`;

const InfoElement = ({ icon, info }) => (
  <FlexContainer>
    <StyledIcon icon={icon} />
    <TextWrapper>{info}</TextWrapper>
  </FlexContainer>
);

export default InfoElement;
