import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 5rem;
  text-align: center;
  color: var(--color-grey);
  font-size: 1.4rem;
  letter-spacing: 1px;

  @media ${({ theme }) => theme.mediaQueries.smaller} {
    margin-top: 3rem;
  }
`;

const StyledLink = styled.a`
  color: inherit;
  transition: color 0.2s ease-out;

  &:hover {
    color: var(--color-black);
  }
`;

const Copyright = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author {
            name
            website
          }
        }
      }
    }
  `);
  return (
    <Wrapper>
      Copyright &copy; {new Date().getFullYear()}{' '}
      <StyledLink href={site.siteMetadata.author.website} target="_blank">
        {site.siteMetadata.author.name}
      </StyledLink>
    </Wrapper>
  );
};

export default Copyright;
