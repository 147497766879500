import React, { useContext } from 'react';
import styled from 'styled-components';

import FooterHeading from '../FooterHeading';
import { LangContext } from '../../../context/LangContext';

const Div = styled.div`
  flex: 1;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const P = styled.p`
  color: var(--color-black);
  font-size: 1.6rem;
  text-align: center;
  line-height: 130%;
  max-width: 30rem;

  @media only screen and (max-width: 330px) {
    font-size: 1.5rem;
  }
`;

const About = () => {
  const { lang } = useContext(LangContext);
  return (
    <Div>
      <FooterHeading>
        {lang === 'en' ? 'About us' : 'Σχετικα με εμας'}
      </FooterHeading>
      <P>
        {lang === 'en'
          ? 'Welcome to our factory. We are glad to help you with everything you need.'
          : 'Καλώς ορίσατε στο εργοστάσιο μας. Θα χαρούμε να είμαστε δίπλα σας σε κάθε σας ανάγκη.'}
      </P>
    </Div>
  );
};

export default About;
