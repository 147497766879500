import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SocialButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  background-color: transparent;
  color: var(--color-black);
  font-size: 2rem;
`;

const StyledLink = styled.a`
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--color-black);
  margin: 0 1rem;
  color: var(--color-black);
  transition: all 0.2s ease-out;

  &:hover {
    background-color: var(--color-black);

    &:hover ${StyledIcon} {
      color: var(--color-primary);
    }
  }
`;

const SocialButton = ({ icon, href }) => (
  <SocialButtonWrapper>
    <StyledLink rel="noreferrer " target="_blank" href={href}>
      <StyledIcon icon={icon} />
    </StyledLink>
  </SocialButtonWrapper>
);

export default SocialButton;
