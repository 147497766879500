import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import { LangContextProvider } from '../../context/LangContext';

import theme from '../../utils/styles/theme';
import GlobalStyle from '../../utils/styles/global';

import Navbar from '../Navigation/Navbar/Navbar';
import Footer from '../Footer/Footer';

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--color-white);
`;

const Wrapper = styled.div`
  flex-grow: 1;
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6rem;
  padding: 3rem auto;
`;

const Layout = ({ notOnePageSection, children }) => {
  // FIX FOR VH ON MOBILE
  const changeVhVariable = () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = typeof window !== 'undefined' && window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    typeof document !== 'undefined' &&
      document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  // Run the function to change the VH variable when the browser is resized
  useEffect(() => {
    changeVhVariable();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <LangContextProvider>
        <FlexContainer>
          <Wrapper>
            <Navbar notOnePageSection={notOnePageSection} />
            <Main>{children}</Main>
          </Wrapper>
          <Footer />
          <GlobalStyle />
        </FlexContainer>
      </LangContextProvider>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
