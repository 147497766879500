import React, { useContext } from 'react';
import styled from 'styled-components';

import FooterHeading from '.././FooterHeading';
import NavLink from './NavLink/NavLink';

import { LangContext } from '../../../context/LangContext';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;

  @media ${({ theme }) => theme.mediaQueries.small} {
    margin-bottom: 2.8rem;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @media ${({ theme }) => theme.mediaQueries.smaller} {
    justify-content: center;
    margin-bottom: 0.3rem;
  }
`;

const Sitemap = () => {
  const { lang } = useContext(LangContext);
  return (
    <Div>
      <FlexContainer>
        <FooterHeading>
          {lang === 'en' ? 'Contents' : 'Περιεχόμενα'}
        </FooterHeading>
        <NavLink link={'/'} info={lang === 'en' ? 'Home' : 'Αρχική'} />
        <NavLink
          link={'/products'}
          info={lang === 'en' ? 'Products' : 'Προϊόντα'}
        />
        <NavLink
          link={'/contact'}
          info={lang === 'en' ? 'Contact' : 'Επικοινωνία'}
        />
      </FlexContainer>
    </Div>
  );
};

export default Sitemap;
