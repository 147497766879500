import React, { createContext, useState, useEffect } from 'react';

const LangContext = createContext({
  lang: 'el',
  toggleLang: () => {},
});

const LangContextProvider = ({ children }) => {
  const [lang, setLang] = useState('el');

  // Fetch preferred language from local storage
  useEffect(() => {
    const defaultLang = JSON.parse(localStorage.getItem('lang')) || 'el';
    setLang(defaultLang);
  }, []);

  // Update local storage on language change
  useEffect(() => {
    localStorage.setItem('lang', JSON.stringify(lang));
  }, [lang]);

  // Change selected language
  const toggleLang = selectedLang => {
    setLang(selectedLang);
  };

  return (
    <LangContext.Provider value={{ lang, toggleLang }}>
      {children}
    </LangContext.Provider>
  );
};

export { LangContext, LangContextProvider };
