import {
  faPhone,
  faEnvelope,
  faMapMarked,
  faMobile,
} from '@fortawesome/free-solid-svg-icons';
import React, { useContext } from 'react';
import styled from 'styled-components';

import FooterHeading from '.././FooterHeading';
import InfoElement from './InfoElement/InfoElement';
import SocialButtons from '../SocialButtons/SocialButtons';

import { LangContext } from '../../../context/LangContext';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @media ${({ theme }) => theme.mediaQueries.smaller} {
    justify-content: center;
    margin-bottom: 4rem;
  }
`;

const ContactInfo = () => {
  const { lang } = useContext(LangContext);
  return (
    <Div>
      <FooterHeading>
        {lang === 'en' ? 'Contact us' : 'Επικοινωνία'}
      </FooterHeading>
      <FlexContainer>
        <InfoElement icon={faMobile} info={'6979002174'} />
        <InfoElement icon={faPhone} info={'2656041796'} />
        <InfoElement icon={faEnvelope} info={'kypselioprotos@gmail.com'} />
        <InfoElement
          icon={faMapMarked}
          info={
            lang === 'en' ? 'Anilio, Metsovo, 44200' : 'Ανήλιο, Μέτσοβο, 44200'
          }
        />
        <SocialButtons />
      </FlexContainer>
    </Div>
  );
};

export default ContactInfo;
