import React from 'react';
import styled from 'styled-components';

import About from './About/About';
import ContactInfo from './ContactInfo/ContactInfo';
import Sitemap from './Sitemap/Sitemap';
import Copyright from './Copyright/Copyright';

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  padding: 4rem 0 1rem 0;
  background-color: var(--color-primary);

  @media ${({ theme }) => theme.mediaQueries.smaller} {
    align-items: center;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media ${({ theme }) => theme.mediaQueries.smaller} {
    flex-direction: column;
  }
`;

const Footer = () => (
  <FooterContainer>
    <FlexWrapper>
      <About />
      <Sitemap />
      <ContactInfo />
    </FlexWrapper>
    <Copyright />
  </FooterContainer>
);

export default Footer;
