import React from 'react';
import styled from 'styled-components';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

import SocialButton from './SocialButton/SocialButton';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media ${({ theme }) => theme.mediaQueries.smaller} {
    margin-bottom: 4rem;
  }
`;

const SocialButtons = () => {
  return (
    <Div>
      <Wrapper>
        <SocialButton
          icon={faFacebook}
          href={`https://www.facebook.com/Kypselioprotos`}
        />
        <SocialButton
          icon={faInstagram}
          href={`https://www.instagram.com/kypseli_o_protos/`}
        />
      </Wrapper>
    </Div>
  );
};
export default SocialButtons;
