import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledLink = styled(Link)`
  text-decoration: none;
  font-family: inherit;
  font-weight: 400;
  color: var(--color-grey-light);
  cursor: pointer;
  padding: 2.7rem 2rem;
  font-size: 1.8rem;
  transition: all 0.2s ease-out;

  &:hover,
  &:active,
  &.active {
    color: var(--color-black);
    background-color: transparent;
  }

  @media ${({ theme }) => theme.mediaQueries.small} {
    margin: 1rem 0;
    font-size: 2.3rem;
  }
`;

const NavItem = ({ name, link, clicked }) => (
  <StyledLink to={link} onClick={clicked} activeClassName="active">
    {name}
  </StyledLink>
);

export default NavItem;
