import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';

const StyledLink = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const LogoNavBar = ({ notOnePageSection, setMenuOpened }) => {
  // Query for logo
  const logo = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/logo/logo.png" }) {
        childImageSharp {
          fixed(height: 55, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <StyledLink to="/">
      <Img alt="Logo" title="Logo" fixed={logo.file.childImageSharp.fixed} />
    </StyledLink>
  );
};

export default LogoNavBar;
