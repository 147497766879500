import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Contained } from '../../Layout/elements';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu/MobileMenu';
import LogoNavbar from '../LogoNavbar';

const StyledHeader = styled.header`
  position: fixed;
  width: 100%;
  max-width: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  background: var(--color-primary);
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.1);
  transition: background 0.2s ease-out;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  overflow-x: hidden;
  justify-content: space-between;
  transition: all 0.2s ease-out;
  user-select: none;
  height: ${({ isMobile }) => (isMobile ? '6rem' : '7rem')};
  @media ${({ theme }) => theme.mediaQueries.small} {
    position: relative;
  }
`;

const Navbar = ({ notOnePageSection }) => {
  const [isMobile, setIsMobile] = useState(null);
  const [menuOpened, setMenuOpened] = useState(false);

  // Change navbar content accordingly
  const changeMobile = () => {
    window.matchMedia('(max-width: 37.5em)').matches
      ? setIsMobile(true)
      : setIsMobile(false);
  };

  // Event listener on resize, so when it change we check or remove desktop menu/mobile menu
  // Better than CSS media query because we don't keep both DOM nodes
  useEffect(() => {
    changeMobile();
    window.addEventListener('resize', changeMobile);
    return () => window.removeEventListener('resize', changeMobile);
  }, []);

  return (
    <StyledHeader>
      <Contained>
        <Wrapper isMobile={isMobile}>
          <LogoNavbar
            notOnePageSection={notOnePageSection}
            setMenuOpened={setMenuOpened}
          />
          {isMobile ? (
            <MobileMenu
              notOnePageSection={notOnePageSection}
              menuOpened={menuOpened}
              setMenuOpened={setMenuOpened}
            />
          ) : (
            <DesktopMenu notOnePageSection={notOnePageSection} />
          )}
        </Wrapper>
      </Contained>
    </StyledHeader>
  );
};

Navbar.propTypes = {
  siteTitle: PropTypes.string,
};

Navbar.defaultProps = {
  siteTitle: ``,
};

export default Navbar;
