import styled from 'styled-components';

export const Contained = styled.div`
  max-width: 124rem;
  z-index: 2;
  padding: 0 4rem;
  margin: 0 auto;
  width: 100%;
  @media ${props => props.theme.mediaQueries.small} {
    padding: 0 3rem;
  }
  @media ${props => props.theme.mediaQueries.smaller} {
    padding: 0 2rem;
  }
`;
